:root {
  --ags-blue: #3F51B5;
  --today: #ebe8d2b4;
  --todayHover: #b4b199;
  --addButton: #89f289;
  --clearButton: #f1f1f1;
  --addButtonHover: #3f8b3f;
  --gearButtonHover: #f2f2f2;
}


body {
  margin: 0;
  padding: 0;
  min-width: 350px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker__aria-live {
  display: none;
}

.fc .fc-toolbar-title {
  font-size: 16pt;
}

.fc .fc-button-primary {
  box-shadow: none !important;
  background-color: #ffffff;
  color: var(--ags-blue);
  border: 1px solid #1c1010;
  font-size: 10pt;
  text-transform: capitalize;
}

.fc .fc-button:hover, .fc-button-active{
  background-color: var(--ags-blue) !important;
  color: #ffffff !important;
}

.fc .fc-today-button:disabled {
  background-color: var(--today) !important;
  color: var(--ags-blue);
  opacity: 1;
}

.fc .fc-add-button {
  background-color: var(--addButton);
} 

.fc .fc-customToday-button {
  background-color: var(--today);
}

.fc .fc-customAddIncrement-button {
  background-color: var(--addButton);
  display: none;      /* Hide the increment button initially */
} 

.fc .fc-customClear-button {
  background-color: var(--clearButton);
  display: none;      /* Hide the button initially */
} 

.fc .fc-gear-button {
  font-size: large;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
} 

.fc .fc-gear-button:hover{
  background-color: var(--gearButtonHover) !important;
}

.fc .fc-customToday-button:hover {
  background-color: var(--todayHover) !important;
}

.fc .fc-add-button:hover {
  background-color: var(--addButtonHover) !important;
}

.fc .fc-day-today {
  background-color: var(--today) !important;
}

.fc .fc-event, .fc-event-title, .fc-event-time {
  font-weight: normal !important;
  font-size: 9pt;
  cursor: pointer;
}

.fc .fc-bg-event {
  color: black;
  opacity: .6;
}

/* Mobile */
@media only screen and (max-width: 500px) {

  .fc .fc-toolbar-title {
    font-size: 12pt;
  }
  .fc .fc-col-header-cell {
    font-size: 10pt;
  }

  .fc .fc-button-primary {
    font-size: 8pt;
  }

  .fc .fc-timegrid-slot {
    font-size: 8pt;
  }
}

@media print {
  .no-print {
      display: none;
  }
  .fc-toolbar {
    display: none !important;
  }
  .MuiAppBar-root {
    display: none !important;
  }
}
